import React from "react"
import '../styles/styles.scss'
import Music from "./music.js"


const IndexPage = (props) => {
  return (
    <Music />
  )
}

export default IndexPage